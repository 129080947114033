var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"box"},[_vm._l((_vm.fileList),function(item,index){return _c('div',{key:index,staticClass:"picShow"},[(
        _vm.sourceType == 'video' &&
          !_vm.is_first &&
          _vm.channel_code &&
          _vm.channel_code != 'HCA140818'
      )?_c('video',{staticStyle:{"width":"100%","height":"100%"},attrs:{"controls":"","src":item.url}}):_vm._e(),(
        _vm.sourceType == 'video' &&
          !_vm.is_first &&
          _vm.channel_code &&
          _vm.channel_code == 'HCA140818'
      )?_c('video',{staticStyle:{"width":"100%","height":"100%"},attrs:{"controls":"","src":item.showItemUrl}}):_vm._e(),(_vm.sourceType == 'pic' || _vm.is_first)?_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.url}}):_vm._e(),(_vm.sourceType == 'pic' || _vm.is_first)?_c('div',{staticClass:"model"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"el-icon-zoom-in",on:{"click":function($event){return _vm.openShow(item.url)}}}),_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){return _vm.del(index)}}})])]):_vm._e(),(_vm.sourceType == 'video' && !_vm.is_first)?_c('i',{staticClass:"del el-icon-delete",on:{"click":function($event){return _vm.del(index)}}}):_vm._e()])}),_c('el-upload',{ref:"uploadPic",class:[_vm.uploadPicTrue ? 'hide' : 'show'],attrs:{"multiple":"","action":_vm.channel_code && _vm.channel_code == 'HCA140818' && _vm.sourceType != 'pic'&&!_vm.is_first
        ? _vm.upUrl
        : _vm.global.materialUpload,"headers":_vm.headers,"list-type":"picture-card","on-remove":_vm.handleRemove,"on-success":_vm.handleSuccess,"on-change":_vm.handleChange,"on-preview":_vm.handlePictureCardPreview,"before-remove":_vm.beforeRemove,"on-error":_vm.handleError,"before-upload":_vm.beforeAvatarUpload,"file-list":_vm.fileList}},[_c('i',{staticClass:"el-icon-plus"})]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"append-to-body":true},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }